import React, {
  Fragment,
  useState,
  useEffect,
} from 'react'
import update from 'immutability-helper'
import equal from 'deep-equal'
import { 
  Button,
  ButtonGroup,
  Form,
  Layout,
  Page,
} from "@shopify/polaris"
import { withFirebase } from '../../../providers/firebase'
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { navigate } from "gatsby"
import CheckoutPreview from './checkoutPreview'
import './create.css'
import Constants from '../../../helpers/constants'
import createRule from '../../../helpers/createRule'
import { updateRule, deleteRule } from '../../../helpers/updateRule'
import CheckoutOfferType from './checkout/checkoutOfferType'
import getSymbolFromCurrency from 'currency-symbol-map';
import PageHeader from '../../../components/pageHeader'
import './common.css';
// Source: https://stackoverflow.com/a/44134328

function Create(props) {
  const { token, shop, location, firebase, host } = props
  const existingOffer = location && location.state && location.state.rule
  const versionAnalytics = location && location.state && location.state.versionAnalytics
  const rules = location && location.state && location.state.rules
  const isEditing = !!existingOffer
  const isCreating = !isEditing
  const initialEditingState = {
    id: existingOffer && existingOffer.id || null, // always null if not created
    offerType: existingOffer && existingOffer.offerType || 'Checkout',
    layout: existingOffer && existingOffer.layout || 'carousel',
    name: existingOffer && existingOffer.name || '',
    banner: existingOffer && existingOffer.banner || '',
    message: existingOffer && existingOffer.message || '',
    title: existingOffer && existingOffer.title || '',
    subtitle: existingOffer && existingOffer.subtitle || '',
    cta: existingOffer && existingOffer.cta || 'Add to cart',
    compareAtPrice: existingOffer && existingOffer.compareAtPrice || {sym:'$',value:""},
    offerBackgroundColor: existingOffer && existingOffer.offerBackgroundColor || '#ffffff',
    ctaBackgroundColor: existingOffer && existingOffer.ctaBackgroundColor || '#ffffff',
    ctaTextColor: existingOffer && existingOffer.ctaTextColor || '#212b36',
    ctaButtonTextColor: existingOffer && existingOffer.ctaButtonTextColor || '#212b36',
    ctaButtonBorderColor: existingOffer && existingOffer.ctaButtonBorderColor || '#767676',
    widgetBorderColor: existingOffer && existingOffer.widgetBorderColor || '#d9d9d9',
    css: existingOffer && existingOffer.css || '',
    buttonBorderRadius: existingOffer && existingOffer.buttonBorderRadius !== undefined ? existingOffer.buttonBorderRadius : 3,
    widgetBorderRadius: existingOffer && existingOffer.widgetBorderRadius !== undefined ? existingOffer.widgetBorderRadius : 5,
    buttonBorder: existingOffer && existingOffer.buttonBorder !== undefined ? existingOffer.buttonBorder : 1,
    widgetBorder: existingOffer && existingOffer.widgetBorder !== undefined ? existingOffer.widgetBorder : 1,
    offerStarting: existingOffer && existingOffer.offerStarting || '',
    offerEnding: existingOffer && existingOffer.offerEnding || '',
    discount: existingOffer && existingOffer.discount !== undefined ? existingOffer.discount : 0,
    product: existingOffer && existingOffer.product || [],
    productNumber: existingOffer && existingOffer.productNumber !== undefined ? existingOffer.productNumber : 1,
    variant: existingOffer && existingOffer.variant || null,
    variants: existingOffer && existingOffer.variants || null,
    enabled: existingOffer && existingOffer.enabled || false,
    visibility: existingOffer && existingOffer.visibility || ['contact_information','shipping_method','payment_method'],
    triggers: existingOffer && existingOffer.triggers || {
      conditions:{
          all:[
              {
                  any: [
                      {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                  ]
              }
              ]
      },
      event: { 
          type: 'foundout',
          params: {
            message: 'rule has found out!'
          }
      }
    },
  variantsTriggers: existingOffer && existingOffer.variantsTriggers || {
    if:{
      all:[
          {fact:'cartProducts',operator: 'hasAny',value: null},
          {fact:'cartProducts',operator: 'Variant', value: null}
      ]
    },
    then: {
      all:[
        {fact:'upsellVariant',operator: 'Variant',value: null}
      ]
    },
    swap: false,
    type: 'manual'
  },
    shopBrain: existingOffer && existingOffer.shopBrain && (existingOffer.shopBrain.inclusion ? existingOffer.shopBrain: {inclusion:[{setting: 'tags',value: null}],exclusion: existingOffer.shopBrain.exclusion}) || null,
    aiConditions: existingOffer && existingOffer.aiConditions || {
      customerTags: [],
      products: []
    },
    swap: existingOffer && existingOffer.swap || false,
    versionB: existingOffer && existingOffer.versionB || null,
    autoAdd: existingOffer && existingOffer.autoAdd || false,
    isExcludeProduct: existingOffer && existingOffer.isExcludeProduct || false,
  }
  const [state, setState] = useState(initialEditingState)
  const [version, setVersion] = useState(state.versionB ? "A":null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [productPickerShow, setProductPickerShow] = useState('product')
  const [error, setError] = useState(null)
  const [productEdit, setProductEdit] = useState(-1)
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc
  );
  const [checkoutOfferType, setCheckoutOfferType] = useState(() => {
    if (state?.variantsTriggers?.type) {
      return state?.variantsTriggers?.type;
    } else {
      return state.shopBrain ? 'manual' : 'ai';
    }
  });
  const [versionData, setVersionData] = useState({
    layout: state.layout, banner: state.banner, message: state.message, title: state.title,
    subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor,
    ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
    widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
    buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
    product: state.product, visibility: state.visibility, variantsTriggers: state.variantsTriggers,
})
const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const handleCheckoutOfferTypeChange = (_checked, newValue) => {
      setCheckoutOfferType(newValue)
      const variantsTriggers = {
        if:{
          all:[
            {fact:'cartProducts',operator: 'hasAny',value: null },
            {fact:'cartProducts',operator: 'Variant', value: null}
          ]
        },
        then: {
          all:[
            {fact:'upsellVariant',operator: 'Variant',value: null}
          ]
        },
        swap: false,
        type: newValue
      }

            if("ai" === newValue){
                if(state.shopBrain === null){
                    if(existingOffer && existingOffer.shopBrain){
                        if(existingOffer.variantsTriggers && "ai" === existingOffer.variantsTriggers.type){
                          setState(update(state, { shopBrain: { $set: existingOffer.shopBrain }, variantsTriggers:{$set:existingOffer.variantsTriggers}}))
                        }else{
                        setState(update(state, { shopBrain: { $set: existingOffer.shopBrain }, variantsTriggers:{$set:variantsTriggers}}))
                        }
                    }else{
                      if(existingOffer && existingOffer.variantsTriggers && "ai" === existingOffer.variantsTriggers.type){
                        setState(update(state, { shopBrain: { $set: { inclusion:[{setting: 'tags',value: null}], exclusion:[{setting: 'tags',value: null}]} }, variantsTriggers:{$set:existingOffer.variantsTriggers}}))
                      }else{
                        setState(update(state, { shopBrain: { $set: { inclusion:[{setting: 'tags',value: null}], exclusion:[{setting: 'tags',value: null}]} }, variantsTriggers:{$set:variantsTriggers}}))
                      }
                    }
                }
            }else{
              if(existingOffer && existingOffer.variantsTriggers && "manual" === existingOffer.variantsTriggers.type){
                setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:existingOffer.variantsTriggers} }))
              }else{
                if(Array.isArray(state.product) && state.product.length > 0 && state.product[0].product.options){
                  setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:variantsTriggers} }))
                }else{
                  setState(update(state, { shopBrain: { $set: null }}))
                }
              }
            }
        }

  let currentState = Object.assign({},state);
  let initialStateForCheck = Object.assign({},initialEditingState);

  if("B"===version){
    currentState = {...state, ...versionData, versionB:{
      layout: state.layout, banner: state.banner, message: state.message, title: state.title,
      subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor,
      ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
      widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
      buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
      product: state.product, visibility: state.visibility, variantsTriggers: state.variantsTriggers,
  }}
  }
  delete currentState.enabled;
  delete initialStateForCheck.enabled;

  const hasUnsavedChanges = (isCreating || isEditing) && !equal(currentState, initialStateForCheck)
  
  const handleRuleDelete = async () => {
    await deleteRule(state.id, token, shop, firebase);

    navigate('/app/offers', {
      state: {
        ...location.state,
        tabIndex: Constants.TAB_INDEX.OFFER_LIST
      },
      replace: true,
    })
  }
  const handleFormSubmit = async (type) => {
    console.log("type", type)
    if (isSubmitting) {
      return
    }
    if((state.product === null || (Array.isArray(state.product) && state.product.length === 0)) && state.shopBrain === null ){
      setError({product: "There is no product selected. Nothing will show in your checkout."})
      return "error"
    }else{
      if(error != null){
        setError(null)
      }
    }
    setIsSubmitting(true)

    try {
      if (isEditing) {
        // TODO
        console.log('update rule.')
        await updateRule({
          ...state /* all rule data */,
          triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
          type: state.discount > 0 ? 'discount' : 'regular', // TODO handle product_discount eventually
        }, token, shop, firebase, version, versionData);
        navigate(
          `/app/offers/create`,
          {
            state: {
              rule: "B"===version? {...state, ...versionData, versionB:{
                layout: state.layout, banner: state.banner, message: state.message, title: state.title,
                subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor,
                ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
                widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
                buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
                product: state.product, visibility: state.visibility, variantsTriggers: state.variantsTriggers,
            }, updatedAt: new Date().toISOString()}:{...state, updatedAt: new Date().toISOString()},
            },
            replace: true,
          }
        )
      } else {
               
        const result = await createRule({
          ...("B"===version? {...state, ...versionData, versionB:{
            layout: state.layout, banner: state.banner, message: state.message, title: state.title,
            subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice, offerBackgroundColor: state.offerBackgroundColor,
            ctaBackgroundColor: state.ctaBackgroundColor, ctaTextColor: state.ctaTextColor, ctaButtonTextColor: state.ctaButtonTextColor, ctaButtonBorderColor: state.ctaButtonBorderColor,
            widgetBorderColor: state.widgetBorderColor, css: state.css, buttonBorderRadius: state.buttonBorderRadius, widgetBorderRadius: state.widgetBorderRadius,
            buttonBorder: state.buttonBorder, widgetBorder: state.widgetBorder, offerStarting: state.offerStarting, offerEnding: state.offerEnding,
            product: state.product, visibility: state.visibility, variantsTriggers: state.variantsTriggers,
        }}:state) /* all rule data */,
          triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
          enabled: "save" === type ? false : true,
        }, token, shop, host)
        if(result && result.data && result.data.rules && result.data.rules.filter( rule => "Post Purchase" !== rule.offerType && "Cart" !== rule.offerType && "extension" !== rule.checkoutType ).length === 1){
          navigate('/app/offers/checkoutInstruction', {
            state: {
            ...location.state
            },
            replace: true,
          }) 

        }else{
          navigate(
            `/app/offers/checkoutOfferItem`,
            {
              state: {
                rule: result.data.rules.find(rule => rule.id === result.data.id),
              },
              replace: true,
            }
          )
        }
        console.log('result from creating rule: ', result)
      }

    }
    catch (e) {
      console.log('Error creating rule: ', e)
    }

    setIsSubmitting(false)
  }

  const addSmartRule =  () => {
    const conditions = {
      conditions:{
          all:[
              {
                  any: [
                      {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                  ]
              }
              ]
      },
      event: { 
          type: 'foundout',
          params: {
            message: 'rule has found out!'
          }
      }
  }
    setState(update(state, { triggers: { $set: conditions }}))
  }
  const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
  const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
  const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
  /*isEditing needs to be fixed, it always call React Hook "useState" is called conditionally error*/
  const offerMarkColor = state.enabled ? "#008060" : "#717171";

  return (
          <PageHeader 
              title={initialEditingState.name} 
              location={location}
              link={"/app/offers"} 
              existingOffer={existingOffer}
              token={token} 
              shop={shop} 
              firebase={firebase} 
              state={state} 
              setState={setState} 
              hasUnsavedChanges={hasUnsavedChanges} 
              handleFormSubmit={handleFormSubmit}
              isEditing={isEditing}
              isSubmitting={isSubmitting}
              windowDimensions={windowDimensions}>
      <Layout>
        <Layout.Section>
        {/*!existingOffer && (!rules || (rules && Array.isArray(rules) && rules.filter( rule => "Post Purchase" !== rule.offerType && "Cart" !== rule.offerType && "extension" !== rule.checkoutType ).length === 0)) ?
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          :
          false
          */} 
        <div style={{ marginBottom: 50, }}>
          <Form 
                disabled={
                  !state.product || 
                  !state.name ||
                  !state.title
                }
                onSubmit={handleFormSubmit}>
            <CheckoutOfferType host={host} token={token} versionAnalytics={versionAnalytics} versionData={versionData} setVersionData={setVersionData} checkoutOfferType={checkoutOfferType} handleCheckoutOfferTypeChange={handleCheckoutOfferTypeChange} shop={shop} addSmartRule={addSmartRule} state={state} setState={setState} version={version} setVersion={setVersion} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} setError={setError} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} locale={locale} currencySymbol={currencySymbol} key={version} setCheckoutOfferType={setCheckoutOfferType} existingOffer={existingOffer}/>
            
            </Form>
          </div>
        </Layout.Section>
        <Layout.Section variant="oneThird">
                <div
                  style={windowDimensions?.width < 795 ? {}:(windowDimensions?.width < 965 ? {
                    maxWidth: 311.34,
                    position: 'fixed',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: "100vh",
                    paddingBottom: "100px",
                  }:{
                    maxWidth: 311.34,
                    position: 'fixed',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: "100vh",
                    paddingBottom: "100px",
                    width: "100%",
                  })}
                >
                  <CheckoutPreview 
                    {...state} 
                    {...props}
                    checkoutOfferType={checkoutOfferType}
                    productEdit={productEdit}
                    image={state.product && state.product.image}
                    price={state.variant && state.variant.price}
                    shopData={shopData}
                  />
                </div>
        </Layout.Section>
      </Layout>
    </PageHeader>
  );
}

export default withFirebase(Create);